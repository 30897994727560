<template>
    <div class="site-section aos-init bg-white position-relative mt-5" data-aos="fade">

        <div class="container">
                <div class="row flex-wrap sta">
                        <div class="col-lg-12 clients">
                            <h5 class="mb-2 mt-4">ABOUT US</h5>
                            <p>The "Matrimonial Service" was started in 1998 to help individuals find a suitable
                                life partner. After running the service for 25 years, Mr.Wencess Laus Lawrence, our
                                Director has made it to one of the top marriage bureaus in Trivandrum. The
                                bureau mainly focuses on helping families within Kerala. As a marriage
                                consultant, the Director has connections with clients both in India and in other
                                countries like the United States, Canada, the United Kingdom, Australia, and
                                more.</p>

                            <p>Located in the heart of Trivandrum city, "marryhelp.in" has a welcoming
                                atmosphere and is easy to reach by phone or email. Our director personally
                                meets with all clients, which helps assess their personality and nature to match
                                them with a suitable partner.</p>

                        </div>
                        <div class="col-12">
                            <img src="@/assets/img/ABOUT.jpg" alt="" class="img-fluid w-100 py-4 lazy">
                        </div>
                    <div class="col-12">
                        <p>At Marryhelp.in, we are trusted and reliable professionals dedicated to helping
                                you find the right life partner by offering a wide range of opportunities for
                                individuals who are serious about commitments. Our experienced team works
                                hard to ensure a perfect match and a fulfilling life for you. </p>

                        <p> Every profile is carefully reviewed, ensuring that no misuse occurs. Your privacy
                            and identity are fully protected from the moment you join Marryhelp.in. Our
                            customer service is exceptional, offering a database of potential partners by
                            filtering out irrelevant or inappropriate profiles Our database is regularly updated,
                            to ensure that the right details of prospective brides and grooms are presented.
                            All relevant information, including social and financial details, is gathered through
                            the membership form, and everything will be strictly confidential.</p>
                            
                            

                            <h6 class="text-danger"><b>Please note that we do not have any other branches or field employees</b></h6>
                    </div>


                </div>
        </div>
</div>
</template>