<!-- <template>

    <div class="row mt-5 happycouples">
          <div class="col-12">
            <div class="sechead">
              <Icon icon="twemoji:red-heart" class="big-icon"/>
              <h4 class="display-6">Our Happy Couples</h4>
              <Icon icon="twemoji:red-heart"class="big-icon"/>
            </div>

          </div>
          
   
          
                        <div class="col-sm-6 col-md-6 col-lg-3 my-2 p-1" v-for="(item, index) in succes_story" :key="index" >
                          <Card :item=item  :base_url="base_url"/>
                        </div>
                        
                  

          
          
        </div>
</template> -->
<template>
  <div class="row mt-5 happycouples">
    <div class="col-12">
      <div class="sechead">
        <Icon icon="twemoji:red-heart" class="big-icon" />
        <h4 class="display-6">Our Happy Couples</h4>
        <Icon icon="twemoji:red-heart" class="big-icon" />
      </div>
    </div>

    <!-- Slider Wrapper -->
    <div class="slider-container col-12">
      <div class="slider pl-2 pr-2" :style="{ transform: `translateX(-${currentSlide * 25}%)` }">
        <!-- Iterate over the succes_story array -->
        <div
          v-for="(item, index) in succes_story"
          :key="index"
          class="col-sm-6 col-md-6 col-lg-3 my-2 p-1 slide-item"
        >
          <Card :item="item" :base_url="base_url" />
        </div>
      </div>

      <!-- Navigation Arrows -->
      <button @click="prevSlide" class="slider-nav prev">&#10094;</button>
      <button @click="nextSlide" class="slider-nav next">&#10095;</button>
    </div>
  </div>
</template>

<script > 
import Card from '@/components/General/SuccessStoryCard1.vue';
import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
   components: {
       Icon,Card
   },
   props: {
       succes_story:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
   data() {
    return {
      currentSlide:0,
      image:"",
      moreLink:"/home"
    };
  },
  methods: {
    nextSlide() {
      if (this.currentSlide < this.succes_story.length - 4) {
        this.currentSlide += 1;
      } else {
        this.currentSlide = 0;
      }
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide -= 1;
      } else {
        this.currentSlide = this.succes_story.length - 4;
      }
    },
  },
  mounted(){
    // console.log('couple',this.base_url)
        AOS.init()
       
  }
}
</script>
<style scoped>
.big-icon {
   font-size: 28px;
}
</style>
<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide-item {
  flex: 0 0 25%;
  /* Adjust the percentage based on how many items per slide you want to show */
}
@media (max-width: 1024px) {
  .slide-item {
  flex: 0 0 30%;
  /* Adjust the percentage based on how many items per slide you want to show */
}
}
@media (max-width: 576px) {
  .slide-item {
  flex: 0 0 55%;
  /* Adjust the percentage based on how many items per slide you want to show */
}
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.slider-nav.prev {
  left: 10px;
}

.slider-nav.next {
  right: 10px;
}
</style>