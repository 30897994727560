<template>
    <div class="col-md-12">
        <div class="inner-form">
            <div class="basic-search">
                <label class="label" for="search by id">Add Request</label>                  
                    <div class="row">   

                        <div class="col-12 col-sm-6 col-md-4 text-start">
                                <label class="fieldlabels text-start">Request For : </label>
                                <select @change="reset" v-model="method"  class="form-select"  aria-label="Default select example">
                                    <option v-for="(item) in option"  :value="item?.id" >{{ item?.title }}</option>
                                </select>
                        </div>
                        <div v-if="method == 1" class="col-12 col-sm-6 col-md-4 text-start">
                                <label class="fieldlabels">Requesting Count : </label> 
                                <input type="number" @keypress="onlyNumber($event)" v-model="count" min="1" placeholder="eg: 5,10" />
                        </div>
                        <div v-else class="col-12 col-sm-6 col-md-4 text-start">
                            <label class="fieldlabels">Requesting Date and Time : </label> 
                            <VueDatePicker  :class="{'is-invalid':v$.datetime.$error}"  v-model="datetime" :min-date="min_date"    class="dp__theme_dark date" format="dd-MM-yyyy hh:mm aa" id="dateInput" placeholder="Date and time"  ></VueDatePicker>
                            <span v-if="v$.datetime.$error" class="error"> {{ v$.datetime.$errors[0].$message.replace('This field','Date and Time') }} </span>
                        </div>
                        <div v-if="method == 3" class="col-12 col-sm-6 col-md-4 text-start">
                            <label  class="fieldlabels text-start">To {{user}} : </label>
                                <select :class="{'is-invalid':v$.user_id.$error}" v-model="user_id"  class="form-select"  aria-label="Default select example">
                                    <option v-for="(item) in users"  :value="item?.id" >{{ item?.name }}{{ item?.user_ids ? `( ${item?.user_ids} )`:'' }}</option>
                                </select>
                                <span v-if="v$.user_id.$error" class="error"> {{ v$.user_id.$errors[0].$message.replace('This field','User') }} </span>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 text-start">
                            <label class="fieldlabels"> Remark :</label> 
                            <input  type="text" v-model="remark"  maxlength="60" placeholder="eg: of all request" />
                        </div>

                    </div>
            </div>

        </div>
    </div>
                                            
    <div class="position-relative ">
        <div class="input-field searchingkey w-100">
            <div class="result-count">
                                                        
            </div>
            <div class="group-btn">
               
                <button class="btn-search" @click="submit" >Add</button>
            </div>
        </div>
    </div>
    <ErrorToast :key="error"  :message="error" @update="errorup" />
    <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<style scoped src="@/assets/css/application.css" />
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { addRequest,RequestFor,Visiting } from '@/util/Services/Authenticated/request';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import moment from 'moment';
export default {
    components:{
        ErrorToast,SuccesToast,VueDatePicker
    },
    name: "AcceptedView",
    setup() {
            const store                  = useStore();
            const method                 = ref(1)
            const option                 = ref([]);
            const count                  = ref(5);
            const remark                 = ref('');
            const error                  = ref(null);
            const success                = ref(null);
            const datetime               = ref(null);
            const user                   = store.getters.gender == "Male" ? "Bride":"Groom";
            const user_id                = ref(null);
            const users                  = ref([{"id":null,"name":"Select User"}]);
            const min_date               = nextDate(new Date(), 1);
            function errorup(){
                error.value = null;
                success.value = null;
            }
            function onlyNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { 
                    $event.preventDefault();
                }
            }
            function nextDate(date,daysToAdd ) {
                    date.setDate(date.getDate() + daysToAdd);
                    date.setHours(0,0,0,0);
                    return date;
                }
            return {
                method,option,count,remark,error,success,errorup,onlyNumber,datetime,min_date,user,user_id,users
            }
    },
    data() {
        return {
                    v$:useVuelidate(),
        }
    },
    validations(){
        return {
                        method   : {required},
                        count    : {required},
                        datetime : {required},
                        user_id  : {required},
        };
    },
    methods:{
        async selcetOptions(){
            await RequestFor().then(r=>{this.option = r?.data?.data ? r?.data?.data : []});
        },
        async VistingUser(){
            await Visiting().then(r=>{r?.data?.data ?  this.users.push(...r?.data?.data) : []});
        },
        reset(){
            this.v$.$reset();
            this.datetime = null;
            this.user_id  = null;
            this.remark   = null;
        },
        async submit(){
            if (this.method == 1) {
                this.datetime = "00-00-0000 00:00:00";
                this.user_id  = "0";
            } else if(this.method == 2 || this.method == 4){
                this.user_id = "0";
            } 
            let datetime = this.datetime ? moment(this.datetime).add(5.5, 'hours') : null;
            this.v$.$validate()
            if(!this.v$.$error){
                await addRequest(this.method,this.count,this.remark,datetime,this.user_id)
                .then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                if (this.success) {
                    this.reset();
                }
            }
        },
    },
    created(){
        this.selcetOptions();
        this.VistingUser();
    }
  
}
</script>
<style scoped>
.label {
        color: red;
        font-size: calc(1.8rem - 1vh);
        padding-bottom: .5rem;
        font-weight: 600;
    }
label{
  padding-top: 1rem;
}
.errors {
      border: 1px solid red !important;
    }
.error{
  margin-top: 10px;
  color: red;
}
.fieldlabels {
        font-size: small;
        color: #757575;
        padding-bottom: 0;
        font-weight: 400;
        padding-top: 1rem;
    }
    input {
      border: 1px solid #cecece;
      border-radius: 5px;
      width: 100%;
      padding: .5rem 1rem;
    }
    .is-invalid .dp__input_wrap {
        border-color: red !important;
    }
    







</style>