<template>
     <expansion-panel>
        <label for="panel7" class="expansion-panel-header"><h6 class="fs-title">Personal Information</h6></label>
            <div class="expansion-panel-content">
                <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fieldlabels">Height :</label> 
                                <!-- <input v-model="form.height"type="number" name="height" placeholder="eg: 165, 180" /> -->
                                <select v-model="form.height" class="form-select" aria-label="Default select example">
                                    <option value="">Select Height</option>
                                    <option v-for="item in heightOptions" :value="item" > {{ item }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Weight(kg):</label> 
                                <input min="0" @keypress="isNumber($event)"  v-model="form.weight" type="number" name="weight" placeholder="eg: 45, 75" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Complexion:</label>
                                <select v-model="form.complexion" class="form-select" aria-label="Default select example">
                                    <option value="">Select type</option>
                                    <option v-for="item in complexion" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Body Type:</label>
                                <select v-model="form.body_type"class="form-select" aria-label="Default select example">
                                    <option value="">Select type</option>
                                    <option v-for="item in bodyType" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Body Status:</label>
                                <select v-model="form.body_status"class="form-select" aria-label="Default select example">
                                    <option value="">Select type</option>
                                    <option v-for="item in bodyStatus" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label class="fieldlabels">Blood Group:</label>
                                <select v-model="form.blood_group"class="form-select" aria-label="Default select example">
                                    <option value="">Select type</option>
                                    <option v-for="item in BloodGroup" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Qualification:</label>
                                <select v-model="form.qualification" class="form-select" aria-label="Default select example">
                                    <option value=""> Select Education</option>
                                    <option v-for="item in qualification" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Qualification Details:</label>
                                <input v-model="form.qualification_details"ype="text" name="Details" placeholder="eg: Engineering, Medical..." />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Occupation: </label>
                                <select v-model="form.profession"class="form-select" aria-label="Default select example">
                                    <option value="" > Select Occupation</option>
                                    <option v-for="item in profession" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Occupation Details:</label>
                                <input v-model="form.profession_details"ype="text" name="Details" placeholder="eg: Engineering, Medical..." />
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Work Location: </label>
                                <input v-model="form.workplace" type="text" name="Location" placeholder="eg: Chennai, Bengaluru" />
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Annual Income: </label>
                                <select v-model="form.income"class="form-select" aria-label="Default select example">
                                    <option value="" > Select Income</option>
                                    <option v-for="item in income" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Diet Preference: </label>
                                <div class="d-flex gap-3 justify-content-start pt-2">
                                    <div class="d-flex">
                                        <input v-model="form.diet_preference" type="radio" name="fav_language" value="Vegetarian">
                                        <label for="html">Vegetarian</label>
                                    </div>
                                    <div class="d-flex">
                                        <input v-model="form.diet_preference" type="radio" name="fav_language" value="Non-Vegetarian">
                                        <label for="css">Non Vegetarian</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <label class="fieldlabels">About the Candidate :</label>
                            <textarea v-model="form.about" name="Detail" form="usrform">{{form?.about ? form?.about : "Enter text here..."}}</textarea>
                        </div>
                        </div>
                    </div> 
                    <input type="submit" name="next" class="next action-button" value="Save" />
                </form>                    
                </fieldset>
            </div>
        </expansion-panel>
        <ErrorToast :key="error"  :message="error" @update="errorup" />
        <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { complexion,bodyType,qualification,profession,income,bodyStatus,BloodGroup } from "@/util/Services/register";
import { required } from "@vuelidate/validators";
import { submit } from "@/util/Services/help";
import { useVuelidate } from '@vuelidate/core'
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { Panel2 } from "@/util/Services/Authenticated/edit";
import { heightValue,heightCheck } from "@/util/Services/height";
export default{
    components:{
        ErrorToast,
        SuccesToast
    },
    data(){
        return{
            v$:useVuelidate(),
            complexion    : [],
            bodyType      : [],
            bodyStatus    : [],
            qualification : [],
            profession    : [],
            income        : [],
            BloodGroup    : [],
            heightOptions : [],
            form:{
                height                : this.data?.details?.height,
                weight                : this.data?.details?.weight,
                complexion            : this.data?.details?.complexion,
                body_type             : this.data?.details?.body_type,
                body_status           : this.data?.details?.body_status,
                qualification         : this.data?.education?.qualification,
                qualification_details : this.data?.education?.qualification_details,
                profession            : this.data?.education?.profession,
                profession_details    : this.data?.education?.profession_details,
                workplace             : this.data?.education?.workplace,
                income                : this.data?.education?.income,
                diet_preference       : this.data?.details?.diet_preference,
                blood_group           : this.data?.details?.blood_group,
                about                 : this.data?.details?.about,
            },
            error   : null,
            success : null,
        }
    },
    validations(){
        return{
                form:{
                    height        : {required},
                    qualification : {required},
                    profession    : {required},
                } 
         } ;
    },
    props:{
      data:{required:true}
    },
    methods:{
       
        submit(){
            this.v$.$validate()
            if(!this.v$.$error){
                Panel2(this.form).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message})
                setTimeout(() => { this.$emit('submit',true);}, 3000)
            }
        }, 
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        async complexionAPI(){
            await complexion().then(r=>{this.complexion = r?.data?.data});
        },
        async bodyTypeAPI(){
            await bodyType().then(r=>{this.bodyType = r?.data?.data});
            await bodyStatus().then(r=>{this.bodyStatus = r?.data?.data});
            await BloodGroup().then(r=>{this.BloodGroup = r?.data?.data});
        },
        async qualificationAPI(){
            await qualification().then(r=>{this.qualification = r?.data?.data});
        },
        async professionAPI(){
            await profession().then(r=>{this.profession = r?.data?.data});
        },
        async incomeAPI(){
            await income().then(r=>{this.income = r?.data?.data});
        },
        errorup(){
            this.error =null;
            this.success = null;
        },
    },
    mounted(){
        this.incomeAPI();
        this.bodyTypeAPI();
        this.professionAPI();
        this.complexionAPI();
        this.qualificationAPI();
        this.heightOptions = heightValue();
        if (!this.heightOptions.includes(this.form.height)) {
            this.form.height =  heightCheck(this.form.height);
        }
    }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
