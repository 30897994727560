<template>
     <div class="row">
          <div class="col-md-6 col-12 m-auto ms-0">
              <div class="d-flex premiummembber">
                  <Icon class="iconify-icon premiummembber_icon" icon="material-symbols-light:workspace-premium-outline"/>
                        <div class="d-grid">
                            <h4 class="m-0 text-black sta">{{ data?.membership ? data?.membership:'Gold Member' }}</h4>
                            <h6 class="text-grey m-0">Customer Support 
                            <a href="tel:'`${data?.customer_support}`" class="text-black">: {{ data?.customer_support }}</a></h6>
                        </div>
              </div>
          </div>
          <div class="col-md-4 col-sm-8 col-12 m-md-auto pt-4 pt-md-0">
              <div class="premiummembberright  sta   d-flex flex-nowrap">
                  <div class="d-grid">
                      <h6>Viewed Matches</h6>
                      <h6 class="m-0">Matches Yet to be viewed</h6>
                  </div>
                  <div class="d-grid ps-3">
                      <h6>: {{ data?.viewed_matches }}</h6>
                      <h6 class="m-0">: {{ data?.matches_yet_to_be_viewed }}</h6>
                  </div>
              </div>

          </div>
    </div>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    data: {
      required: true,
    },
  },
  created() {
      AOS.init()  
    },
  };
  </script>
     <style scoped src="@/assets/css/application.css" ></style>