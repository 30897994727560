<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0 ">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-9 col-xl-10  col-12 p-0 pt-md-0 pt-xl-0 pt-md-5  pt-sm-5">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button @click="JoinTab" :class="{'active':tab == 0}" class="nav-link " id="joined-tab" data-bs-toggle="tab"
                                            data-bs-target="#joined" type="button" role="tab" aria-controls="joined"
                                            aria-selected="true">Just Joined({{ justJoined?.total ? justJoined?.total : 0 }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="MatcheTab" :class="{'active':tab == 1}" class="nav-link" id="matches-tab" data-bs-toggle="tab"
                                            data-bs-target="#matches" type="button" role="tab" aria-controls="matches"
                                            aria-selected="false">My Matches({{ myMatches?.total ? myMatches?.total : 0 }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="PremiumTab" :class="{'active':tab == 2}" class="nav-link" id="premium-tab" data-bs-toggle="tab"
                                            data-bs-target="#premium" type="button" role="tab" aria-controls="premium"
                                            aria-selected="false">Premium({{ Premium?.total ? Premium?.total : 0 }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="SuggestedTab" :class="{'active':tab == 3}"  class="nav-link" id="mutual-tab" data-bs-toggle="tab"
                                            data-bs-target="#mutual" type="button" role="tab" aria-controls="mutual"
                                            aria-selected="false">Suggested({{ Suggested?.total ? Suggested?.total : 0 }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="AlreadyViewedTab"  :class="{'active':tab == 4}"  class="nav-link" id="already-tab" data-bs-toggle="tab"
                                            data-bs-target="#already" type="button" role="tab" aria-controls="already"
                                            aria-selected="false">Already Viewed({{ alreadyViewed?.total ? alreadyViewed?.total : 0 }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="ViewedTab"  :class="{'active':tab == 5}"  class="nav-link" id="Viwed-tab" data-bs-toggle="tab"
                                            data-bs-target="#Viwed" type="button" role="tab" aria-controls="Viwed"
                                            aria-selected="false">Viewed Your Profile({{ Viewed?.total ? Viewed?.total : 0 }})</button>
                                    </li>
                                   

                                </ul>
                                <div class="p-2">

                                    <div class="tab-content" id="myTabContent">

                                        <!-- Just Joined Table -->
                                        <div class="tab-pane fade   " :class="{'active show':tab == 0}" id="joined" role="tabpanel" aria-labelledby="joined-tab">
                                            <Spinner v-if="jloading" />
                                            <div v-else class="row table">
                                                <div  v-if="joinData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in joinData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="justJoined?.next_page_url != null && !jloading && !justJoinedloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="JoinMore()" >Show More</button>
                                               <div v-if="justJoinedloading && !jloading" class="spinner-border" role="status">
                                               </div>
                                        </div>

                                        <!-- My Matches Table -->
                                        <div class="tab-pane fade" :class="{' active show':tab == 1}" id="matches" role="tabpanel" aria-labelledby="matches-tab">
                                            <Spinner v-if="mloading" />
                                            <div v-else :key="loading"  class="row table">
                                                <div v-if="matchesData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in matchesData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="myMatches?.next_page_url != null && !mloading && !matchesloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="MoreMyMatches()" >Show More</button>
                                               <div v-if="matchesloading && !mloading" class="spinner-border" role="status">
                                                </div>
                                        </div>


                                        <!-- Premium Table -->
                                        <div class="tab-pane fade"  :class="{' active show':tab == 2}" id="premium" role="tabpanel" aria-labelledby="premium-tab">
                                            <Spinner v-if="ploading" />
                                            <div v-else class="row table">
                                                <div v-if="premiumData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in premiumData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="Premium?.next_page_url != null && !ploading && !premiumloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MorePremium()" >Show More</button>
                                            <div v-if="premiumloading && !ploading" class="spinner-border" role="status">
                                            </div>
                                        </div>

                                        <!-- Mutual Table -->
                                        <div class="tab-pane fade"  :class="{' active show':tab == 3}" id="mutual" role="tabpanel" aria-labelledby="mutual-tab">
                                            <Spinner v-if="sloading" />
                                            <div v-else class="row table">
                                                <div v-if="suggestedData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in suggestedData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="Suggested?.next_page_url != null && !sloading && !suggestedloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MoreSuggested()" >Show More</button>
                                            <div v-if="suggestedloading && !sloading" class="spinner-border" role="status">
                                            </div>
                                        </div>

                                        <!-- Already Viewed Table -->
                                        <div class="tab-pane fade"  :class="{'active show':tab == 4}" id="already" role="tabpanel" aria-labelledby="already-tab">
                                            <Spinner v-if="aloading" />
                                            <div v-else class="row table">
                                                <div v-if="alreadyViewedData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in alreadyViewedData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="alreadyViewed?.next_page_url != null && !aloading && !alreadyloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MoreAlreadyViewed()" >Show More</button>
                                            <div v-if="alreadyloading && !aloading" class="spinner-border" role="status">
                                            </div>
                                        </div>
                                         <!-- Viwed Table -->
                                         <div class="tab-pane fade"  :class="{'active show':tab == 5}" id="Viwed" role="tabpanel" aria-labelledby="already-tab">
                                            <Spinner v-if="vloading" />
                                            <div v-else class="row table">
                                                <div v-if="ViewedData.length" class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in ViewedData" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="Viewed?.next_page_url != null && !vloading && !viewedloading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="ViewedMore()" >Show More</button>
                                            <div v-if="viewedloading && !vloading" class="spinner-border" role="status">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>


                    <div class="col-lg-3 col-xl-2  d-none d-lg-block p-0">
                      <!-- Quick Search -->
                       <QuickSearch :martial_status="martial_status" :religion="religion"/>
                       <!-- Happy Couples -->
                       <HappyCouple  :successStory="successStory" />
                    </div>
                      
                  </div>
              </div>
  
  
  
          </div>
          <MobileNav :active="active"/>
    
  </body>
    </template>

    <script>
    import AOS from 'aos';
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue";
    import Card from "@/components/Authenticated/UserCard.vue";
    import QuickSearch from "@/components/Authenticated/Matches/QuickSearch.vue";
    import HappyCouple from "@/components/Authenticated/Matches/HappyCouples.vue";
    import { justJoined,myMatches,Premium,Suggested,AlreadyView,successStory,Viewed } from "@/util/Services/Authenticated/matches";
    import { martial_status,religion } from "@/util/Services/home";
    import Spinner from "@/components/Authenticated/Spinner.vue";
    import { ref } from 'vue';
    export default {
      props: ['id'],
      name: "AcceptedView",
      components: {
        MainNav,SideNav,Icon,MobileNav,Card,QuickSearch,HappyCouple,Spinner
      },
      setup() {
            const joinCount           = ref(1);
            const myMatchesCount      = ref(1);
            const premiumCount        = ref(1);
            const suggestedCount      = ref(1);
            const alreadyViewedCount  = ref(1);
            const loading             = ref(false);
            const jloading            = ref(false);
            const mloading            = ref(false);
            const ploading            = ref(false);
            const sloading            = ref(false);
            const aloading            = ref(false);
            const vloading            = ref(false);
            const justJoinedloading   = ref(false);
            const matchesloading      = ref(false);
            const premiumloading      = ref(false);
            const suggestedloading    = ref(false);
            const alreadyloading      = ref(false);
            const viewedloading       = ref(false);
            const show                = ref(false);
            const active              = 'Match';
            const tab                 = 0;
            //Just Joined
            const joinData          = ref([]);
            const justJoined        = ref([]);
            //My Matches
            const matchesData       = ref([]);
            const myMatches         = ref([]);
            //Premium
            const premiumData       = ref([]);
            const Premium           = ref([]);
            //Suggested
            const suggestedData     = ref([]);
            const Suggested         = ref([]);
             //Already Viewed
            const alreadyViewedData = ref([]);
            const alreadyViewed     = ref([]);
            //Viewed
            const ViewedCount       = ref(1);
            const ViewedData        = ref([]);
            const Viewed            = ref([]);

            const successStory      = ref([]);
            const martial_status    = ref([]);
            const religion          = ref([]);
            const numcount          = 0;
            return {
                loading,show,active,tab,joinCount,myMatchesCount,premiumCount,suggestedCount,alreadyViewedCount,
                justJoined,joinData,matchesData,myMatches,premiumData,Premium,suggestedData,Suggested,
                alreadyViewedData,alreadyViewed,successStory,martial_status,religion,ViewedCount,ViewedData,Viewed,
                numcount,justJoinedloading,matchesloading,premiumloading,suggestedloading,alreadyloading,viewedloading,jloading,
                mloading,ploading,sloading,aloading,vloading
            }
        },
      methods: {
        sidenav() { this.show = !this.show; },
        JoinMore(){
            this.justJoinedloading = true;
            this.joinCount++;
            this.JoinedAPI();
        },
        JoinTab(){
            this.numcount = 5;
            this.joinCount = 1;
            this.joinData = [];
            this.JoinedAPI();
        },
        async JoinedAPI(){
            if (this.joinCount == 1)  this.jloading = true;
            await justJoined(this.joinCount).
            then(r=>{this.justJoined = r?.data?.data,r?.data?.data?.data ? this.joinData.push(...r?.data?.data?.data):[]});
            this.justJoinedloading = false;
            if (this.joinCount == 1) this.jloading = false; this.spinner();
        },
        MoreMyMatches(){
            this.matchesloading = true;
            this.myMatchesCount++;
            this.MyMatches();
        },
        MatcheTab(){
            this.myMatchesCount = 1;
            this.numcount = 5;
            this.matchesData = [];
            this.MyMatches();
        },
        async MyMatches(){
            if (this.myMatchesCount == 1) this.mloading = true;
            await myMatches(this.myMatchesCount).
            then(r=>{this.myMatches = r?.data?.data,r?.data?.data?.data ? this.matchesData.push(...r?.data?.data?.data) : r?.data?.data?.data});
            this.matchesloading = false;
            if (this.myMatchesCount == 1) this.mloading = false; this.spinner();
        },
        MorePremium(){
            this.premiumloading = true;
            this.premiumCount++;
            this.PremiumAPI();
        },
        PremiumTab(){
            this.numcount = 5;
            this.premiumCount = 1;
            this.premiumData = [];
            this.PremiumAPI();
        },
        async PremiumAPI(){
            if (this.premiumCount == 1) this.loading = true;this.ploading = true;
            await Premium(this.premiumCount).
            then(r=>{this.Premium = r?.data?.data,r?.data?.data?.data ? this.premiumData.push(...r?.data?.data?.data) : []});
            this.premiumloading = false;
            if (this.premiumCount == 1) this.ploading = false;this.spinner();
        },
        MoreSuggested(){
            this.suggestedloading = true;
            this.suggestedCount++;
            this.SuggestedAPI();
        },
        SuggestedTab(){
            this.numcount = 5;
            this.suggestedCount = 1;
            this.suggestedData = [];
            this.SuggestedAPI();
        },
        async SuggestedAPI(){
            if (this.suggestedCount == 1) this.loading = true;this.sloading = true;
            await Suggested(this.suggestedCount).
            then(r=>{this.Suggested = r?.data?.data,r?.data?.data?.data ? this.suggestedData.push(...r?.data?.data?.data):[]});
            this.suggestedloading = false;
            if (this.suggestedCount == 1)this.sloading = false; this.spinner();
        },
        MoreAlreadyViewed(){
            this.alreadyloading = true;
            this.alreadyViewedCount++;
            this.AlreadyViewedAPI();
        },
        AlreadyViewedTab(){
            this.numcount = 5;
            this.alreadyViewedCount = 1;
            this.alreadyViewedData = [];
            this.AlreadyViewedAPI();
        },
        async AlreadyViewedAPI(){
            if (this.alreadyViewedCount == 1){
                this.loading = true;
                this.aloading = true;
            } 
            await AlreadyView(this.alreadyViewedCount).
            then(r=>{this.alreadyViewed = r?.data?.data,r?.data?.data?.data ? this.alreadyViewedData.push(...r?.data?.data?.data): r?.data?.data?.data});
            this.alreadyloading = false;
            if (this.alreadyViewedCount == 1){
                this.aloading = false; this.spinner();
            }
        },
        ViewedMore(){
            this.viewedloading = true;
            this.ViewedCount++;
            this.ViewedAPI();
        },
        ViewedTab(){
            this.numcount = 5;  
            this.ViewedCount = 1;
            this.ViewedData = [];
            this.ViewedAPI();
        },
        async ViewedAPI(){
            if (this.ViewedCount == 1) 
            {
                this.loading = true;
                this.vloading = true;
            }
            
            await Viewed(this.ViewedCount).
            then(r=>{this.Viewed = r?.data?.data,r?.data?.data?.data ? this.ViewedData.push(...r?.data?.data?.data): r?.data?.data?.data});
            this.viewedloading = false;
            if (this.ViewedCount == 1) {
                this.vloading = false;
                 this.spinner();
            }
        },
        async successStoryAPI(){ await successStory().then(r=>{this.successStory = r?.data?.data}); },
        async Martial_Status(){ await martial_status().then(r=>{ this.martial_status = r?.data?.data }); },
        async Religion(){ await religion().then(r=>{this.religion = r?.data?.data }); },
        spinner(){
            this.numcount++;
              // console.log(this.numcount,'numcount');
            if( this.numcount > 5){
                this.numcount == 0;
                var overlayLoader = document.querySelector('.spinner-border');
                if (overlayLoader)  setTimeout(() => overlayLoader.classList.add('fade-out'), 15);
                setTimeout(() =>  this.loading = false, 20);
            }
        },
      },
      mounted() {
          AOS.init()  
          this.successStoryAPI();
          this.Martial_Status();
          this.Religion();
      },
      created(){
          this.tab = this.id;
          this.JoinedAPI();
          this.MyMatches();
          this.PremiumAPI();
          this.SuggestedAPI();
          this.AlreadyViewedAPI();
          this.ViewedAPI();
      }
    };
    </script>
    <style scoped src="@/assets/css/application.css" />
    <style scoped src="@/assets/css/no_data.css" />
    <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
    <style scoped src="@/assets/css/components/Authenticated/spinner.css"/>
  <style scoped >
   .select-wrap .icon {
      position : absolute;
      right : 0px;
      top : 0%;
      -webkit-transform : translateY(50%) translateX(-15px);
      -ms-transform : translateY(50%) translateX(-15px);
      transform : translateY(50%) translateX(-15px);
      transition : .3s;
    }
    .form-control{
      
        font-size : .7rem !important;
        padding : .175rem !important;
    }
    input{
        font-size : .7rem !important;
    }
  </style>
   
    